<template>
  <SearchResultsTable
    type="regioloketten"
    :columns="columns"
    :use-header="false"
    :loading="loading"
    :data="data"
    :error="error"
  >
    <template #name-data="{ row }">
      <UPopover 
        mode="hover" 
        :open-delay="500" 
        :disabled="isMounted && !grid.sm"
        
      >
        <RowHead
          :name="row.name"
          :image="row.imageUrl"
          :to="'/regioloketten/' + row.slug"
          :expanded="expanded"
          :heading-class="headingClass"
          class="mt-1"
          :icon="icons.regioloketten"
        />

        <template #panel>
          <RegioloketPreview 
            :data="row"
          />
        </template>
      </UPopover>
    </template>
    <template #sectoren-data="{ row }">
      <Sectoren :data="row.sectoren" />
    </template>
    <template #regio-data="{ row }">
      <Plaats :label=" row.regio || row.plaats" :distance="row._rankingInfo?.geoDistance" />
    </template>
    <template #phone-data="{ row }">
      <ContactMethod type="phone" :data="row.phone" />
    </template>
    <template #email-data="{ row }">
      <ContactMethod type="email" :data="row.email" />
    </template>      
  </SearchResultsTable>
</template>

<script lang="ts" setup>
import type { AlgoliaRegioloket } from '~~/types/algolia';


const props = defineProps<{
  loading?: boolean
  data: AlgoliaRegioloket[]
  error?: any
  expanded?: boolean
  headingClass?: string
}>()


const { icons } = useConstants()


const { grid } = useResponsive()
const { isMounted } = useUiState()

const columns = computed(() => {
  const list = [
    {
      key: 'name'
    },
    {
      key: 'sectoren'
    }
  ]

  if(props.expanded) {
    list.push(...[
      {
        key: 'regio'
      },
      {
        key: 'phone'
      },
      {
        key: 'email'
      },
    ])
  }

  return list
})
</script>

<style>

</style>