<template> 
  <IconCard
    :icon="icon"
    :color="color"
    use-ring
    :ui="{
      divide: 'divide-y divide-gray-200 dark:divide-gray-700',
      body: {
        padding: 'p-0 sm:p-0'
      },
      footer: {
        padding: 'pt-2 pb-3'
      }
    }"
  > 
    <template #header>
      <div class="flex justify-between gap-3">
        <h6 class="text-sm mb-0 mt-1 sm:mt-2 lg:mt-1.5">
          {{ title }}
        </h6>
        <Hint :text="hint" class="shrink-0" />
      </div>
      
    </template>
    
    <div :class="!fullList ? 'md:max-h-[24rem] overflow-scroll' : ''">
      <RegiolokettenTable 
        :data="loketListCard" 
        :loading="loading" 
        :error="error" 
        :heading-class="fullHeading || fullList ? 'max-w-full sm:max-w-full whitespace-nowrap' : undefined" 
      />
    </div>

    <template v-if="extraRecords > 0 && !fullList && isMounted" #footer>
      <NavigatorDetailsList 
        v-model="showAll"
        :title="title"
        :icon="icon"
        :color="color"
        :show-more-label="showAllBtnLabel"
      >
        <RegiolokettenTable 
          :data="loketList" 
          :loading="loading" 
          :error="error"
          expanded
        />   
      </NavigatorDetailsList>
    </template>

   
  </IconCard>
</template>

<script lang="ts" setup>
  import type { AlgoliaRegioloket } from '~~/types/algolia';
  import type { ButtonColor } from '#ui/types'
  const props = withDefaults(defineProps<{
    data: AlgoliaRegioloket[],
    loading: boolean,
    error: any
    isGeoSearch?: boolean
    title?: string
    color?: BaseColor
    fullHeading?: boolean
    fullList?: boolean
    useDistance?: boolean
    baseLength?: number
  }>(), {
    data: () => [],
    color: 'primary',
    baseLength: 5
  })


  const { sectors } = useConstants()
  const { regioloketten: hint } = useNavigatorHints().entities


  const baseListLength = computed(() => props.fullList ? props.data.length : props.baseLength)
  const loketList = computed(() => {
    return props.data.map((rec: any) => {
      return {
        ...rec,
        sectoren: rec.sectoren.map((s: string) => sectors.find(sec => sec.label === s || sec.altNames.includes(s))?.id).filter((val: string | undefined) => !!val),
        distance: rec._rankingInfo?.geoDistance
      }
    })
  })

  const loketListCard = computed(() => loketList.value?.slice(0, baseListLength.value))

  // Variables to determine if the "show all" panel should be used
  const extraRecords = computed(() => props.data.length - baseListLength.value)
  const showAll = ref(false)
  const { isMounted } = useUiState()
  const title = computed(() => props.loading || !isMounted.value ? 'Regioloketten aan het zoeken...' : props.title ? props.title : `Regioloketten ${props.isGeoSearch ? ' binnen jouw zoekgebied' : ''}`)
  const { icons } = useConstants()
  const icon = icons['regioloket']
  const showAllBtnLabel = computed(() => `${ showAll.value ? 'verberg' : 'toon' } ${ props.loading ? '' : extraRecords.value } andere loketten`)

</script>

<style lang="postcss">
.logo img {
  @apply object-contain object-center
}
</style>