export const useNavigatorHints = () => {
  const entities = {
    regioloketten: 'Naast het landelijke Onderwijsloket bestaan er tientallen regioloketten. Zij kunnen je verder helpen met het vinden van een geschikte werkplek of opleiding binnen de betreffende regio. Elk regioloket biedt zijn eigen soorten dienstverleningen richt zich op verschillende onderwijssectoren.',
    opleidingen: 'Opleidingen waarmee je je lesbevoegdheid kunt behalen en opleidingen voor leidinggevende of ondersteunende functies.',
    scholen: 'Alle scholen uit het primair onderwijs en voortgezet onderwijs.',
  }

  return {
    entities
  }
}
